import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import FooterFunction from "../../components/app/functions/FooterFunction";
import {ReactComponent as ArrowIcon} from '../../assets/arrow-right-icon.svg';

import TokenIcon from '../../assets/token.png';
import TokenSmallIcon from '../../assets/token-small.png';
import {ReactComponent as CryptoIcon} from '../../assets/close-circle-linear.svg';
import {deleteWalletAddress, getCustomersWallet, setWalletAddress} from "../../logic/server/api";
// import {PublicKey} from "@solana/web3.js";
import {useSprings, animated, useSpringRef} from "@react-spring/web";
import {PublicKey} from "@solana/web3.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Claim = () => {
    
    
    const positions = [
        { top: '19px', left: '55px' },
        { top: '164px', left: '-57px' },
        { top: '65px', left: '314px' },
        { top: '328px', left: '216px' },
        // Add more positions as needed
    ];
    
    const [springs, api] = useSprings(
        positions.length,
        index => ({
            from: { transform: 'rotate(0deg)' },
            to: async (next) => {
                while (true) {
                    // await next({ transform: 'rotate(360deg)' });
                    // await next({ transform: 'rotate(0deg)' });
                    await next({ transform: 'rotate3d(0, 1, 0, 360deg)' });
                    await next({ transform: 'rotate3d(0, 1, 0, 0deg)' });
                }
            },
            config: { duration: 3000 }, // adjust the duration for the spin speed
        })
    );
    api.start();
    
    const [walletAddress, SetWalletAddress] = useState('');
    const [error, SetError] = useState(false);
    const [walletAddressSaved, SetWalletAddressSaved] = useState('');
    let count = useSelector(state => state.counter.count);
    count = count || localStorage.getItem('points');
     useEffect(() => {
        getWallet();
    }, []);

    async function getWallet() {
        const resp = await getCustomersWallet();
        if (resp?.person?.crypto_wallet?.address) {
            SetWalletAddress(resp?.person?.crypto_wallet?.address);
            SetWalletAddressSaved(resp?.person?.crypto_wallet?.address);
        }
        console.log(resp);
    }
    async function deleteWallet() {
        return await deleteWalletAddress(walletAddress);
    }
    async function addWallet(walletToSave) {
        return await setWalletAddress(walletToSave);
    }
    const handleChange = (event) => {
        SetError(false);
        // console.log(event.target.value);
        SetWalletAddress(event.target.value);
        if (event.target.value.length >= 30 ) {
            setWallet(event.target.value);
        }
    };
    const clearWallet = (event) => {
        if (SetWalletAddressSaved) {
            deleteWallet().then(res => {
                console.log(res);
                SetWalletAddress('');
                SetWalletAddressSaved('');
            }).catch(err => {
                SetError(true);
            });
        } else {
            SetWalletAddress('');
            SetWalletAddressSaved('');
        }
    };
    function setWallet(address) {
        const walletToSave = address;
        if (walletToSave) {
            try {
                // const publicKey = new PublicKey(walletToSave);
                //
                // console.log(PublicKey.isOnCurve(publicKey.toBytes()))
                if (walletAddressSaved) {
                    deleteWallet().then(() => {
                        addWallet(walletToSave).then(() => {
                            SetWalletAddressSaved(walletToSave);
                            toast.success('Wallet address saved successfully!');
                        }).catch(err => {
                            SetWalletAddressSaved('');
                            SetError(true);
                            toast.error('Failed to save wallet address!');
                        });
                    }).catch(err => {
                        SetWalletAddressSaved('');
                        SetError(true);
                        toast.error('Failed to delete existing wallet address!');
                    });
                } else {
                    addWallet(walletToSave).then(() => {
                        SetWalletAddressSaved(walletToSave);
                        toast.success('Wallet address saved successfully!');
                    }).catch(err => {
                        // SetWalletAddressSaved('');
                        SetError(true);
                        toast.error('Failed to save wallet address!');
                    });
                }
            } catch (err) {
                toast.error('Invalid wallet address format!');
                return false;
            }
        }
    };

    const navigateToWeb = () => {
        window.open('https://dev.picklezone-game-landing.techfunder.de', '_blank')

    }

        return (
        <TelegramScreen
            className={`main-wrapper white-background`}  id='gesuredZone'>
            <div className='picklezone-main-container picklezone-main-container-dim'>
                <div className={'top-container'} style={{backgroundColor: '#fff'}}>
                  
                   
                 <div className="animation-container1">
                  {springs.map((styles, index) => (
                    <animated.div
                      key={index}
                      style={{
                        ...styles,
                        position: 'absolute',
                        width: '92px',
                        height: '92px',
                        background: `url(${TokenSmallIcon})`,
                        backgroundSize: 'cover',
                        borderRadius: '50%',
                        top: `calc(0% + ${positions[index].top})`,
                        left: `calc(0% + ${positions[index].left})`,
                      }}
                    />
                  ))}
                </div>
                    
                    
                 <div className={'token-block'}>
                     <img src={TokenIcon} />
                                
                 </div>
                    
                <div className={'redeem-cards-container gap-of-wrapper-of-ppurs'}>


                    <div className='wrapper-for-crypto-wallet'>
                        
                     <div>
                    <p className={'text-name-roboto-20'}>Redeem tokens</p>
                 
                    <p className={'bold-blue-text-name-roboto'}>Connected wallet (Solana)</p>

                    </div>
                        
                     <form className='form-for-crypto-wallet'>
                     <label></label>
                        <input
                          type="text"
                          className={`input-for-crypto-wallet ${error ? 'input-error-text' : ''}`}
                          placeholder="0xdafea34..."
                          onChange={handleChange}
                          value={walletAddress}
                          required
                        />
                         <ArrowIcon className={`set-wallet ${walletAddress ? 'hide-button' : 'show-button'}`} onClick={setWallet}></ArrowIcon>
                         <CryptoIcon className={`crypto-icon ${walletAddress? 'show-button' : 'hide-button'}`} width="24" height="24"
                         onClick={clearWallet}/>
                    </form>

                  <div className={`cards__redeem-button  ${walletAddressSaved ? '' : 'disabled'}`} onClick={navigateToWeb}>
                      Redeem now
                
                  </div>

                    </div>

                </div>
                </div>
                <div className='forms__bottom'>
                    <FooterFunction currentPage={'redeem'}/>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}

                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>)

}
export default Claim;
