import React, {createRef, useEffect, useMemo, useRef, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";

import {useDispatch, useSelector} from "react-redux";
import {increment, incrementByAmount, setAmount} from "./counterSlice";
import FooterFunction from "../../components/app/functions/FooterFunction";
import {
    animated, BailSignal,
    useChain, useSpring,
    useSpringRef, useSprings, useSpringValue,
    useTransition
} from '@react-spring/web';
import timer from '../../assets/stopwatch.svg';
import playIcon from '../../assets/play-icon-player.svg';
import pauseIcon from '../../assets/pause-icon2.svg';
import lives from '../../assets/lives-icon.svg';
import HeaderFunction from "../../components/app/functions/HeaderFunction";
import Splash from "../splash/splash";
import {openApp} from "./settingsSlice";
import Pickleball from "./pickleball";
import {ReactSVG} from "react-svg";
import {useTelegram} from "../../hooks/useTelegram";
import {DropI, PickleballClass, PickleballI} from "./pickleballLogic";

import paddleThree from  "../../assets/paddledefault.png";
import {
    claimPoints,
    claimSeveralPoints,
    equipItem,
    getEquipment,
    getGameReward,
    getNextBall
} from "../../logic/server/api";
// import court from '../../assets/court.png';
import court from '../../assets/backgrounds/field3.png';
import {ACTIVE_TIME, SlEEP_TIME} from "../../logic/server/Variables";

interface AnimatedStyle {
    x: number;
    y: number;
    opacity: number;
}


const AnimationScreen= React.memo(({collectibles}: any) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    // custom cursor
    const [MousePosition, setMousePosition] = useState({
        left: -250,
        top: -250
    });
    const [paddle, setPaddle] = useState(paddleThree);
    const [reward, setReward] = useState({image: '', asset: '', rarity: '', id: '', name: '', price: 0});

    const [ballType, set] = useState<PickleballI[] | []>([]);
    const [nextBall, setNextBall] = useState<PickleballI | null>(null);

    const [isClicked, setIsClicked] = useState(false);
    const [points, setPoints] = useState(0);
    const [coordinates, setCoordinates] = useState({x: 0, y: 0});
    const [isGameStarted, setGameStarted] = useState(false);
    const intervalRef = useRef<number | null>(null);
    // timer 5 minutes
    const [time, setTime] =
        useState(localStorage.getItem('time') ? Number(localStorage.getItem('time')) : ACTIVE_TIME);
    const [sleepTime, setSleepTime] =
        useState(localStorage.getItem('sleepTime') ? Number(localStorage.getItem('sleepTime')) : SlEEP_TIME);
    const [timeOut, setTimeOutState] =
        useState(!!(localStorage.getItem('timeOut') && (JSON.parse(localStorage.getItem('timeOut') || ''))));

    console.log(timeOut, sleepTime, localStorage.getItem('sleepTime'));
    const cancelMap = useMemo(() => new WeakMap(), []);
    // on animation start
    const nextMap = useMemo(() => new WeakMap(), []);

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // animation on paddle click
    const [paddleProps, apiPaddle] = useSpring(
        (points: any) => ({
            from: { transform: 'rotate3d(0, 0, 0, 0deg)' },
            to: [
                { transform: 'rotate3d(1, 0, 1, -3deg)' },
                { transform: 'rotate3d(1, 0, 1, 0deg)' },
            ],
            config: { tension: 200, friction: 15, duration: 200 },
        })
    );
    // for the points values
    const clickTransitions = useTransition(isClicked, {
        from: { transform: 'rotate3d(0, 0, 0, 0deg)' },
        to: [
            { transform: 'rotate3d(0, 0, 1, 5deg)' },
            { transform: 'rotate3d(0, 0, 1, 0deg)' },
        ],
        config: { tension: 200, friction: 15 },
    });


    // active time timer
    useEffect(() => {
        let interval: any;
        if (!timeOut && time > 0 && isGameStarted) {
            interval = setInterval(() => {
                localStorage.setItem('time', (time - 10).toString());
                setTime((prevTimer1) => prevTimer1 - 10);
            }, 10);
        } else if (time === 0 && !timeOut) {
            localStorage.setItem('time',  '');
            clearInterval(interval);
            setTimeOutState(true); // Switch to Timer 2 when Timer 1 finishes
            localStorage.setItem('timeOut',  JSON.stringify(true));
            setSleepTime(SlEEP_TIME); // Reset Timer 2
        }
        return () => clearInterval(interval);

    }, [time, timeOut, isGameStarted]);

    // sleep time timer
    useEffect(() => {
        let interval: any;
        if (timeOut && sleepTime > 0) {

            interval = setInterval(() => {
                localStorage.setItem('sleepTime', (sleepTime - 10).toString());
                setSleepTime((prevTimer1) => prevTimer1 - 10);
            }, 10);
        } else if (sleepTime === 0 && timeOut) {
            localStorage.setItem('sleepTime',  '');
            clearInterval(interval);
            setTimeOutState(false);
            localStorage.setItem('timeOut',  JSON.stringify(false));
            setTime(ACTIVE_TIME);
        }
        return () => clearInterval(interval);

    }, [sleepTime, timeOut]);

    // Format time into mm:ss:ms format
    const formatTime = (timeInMs: number) => {
        const minutes = Math.floor(timeInMs / (60 * 1000));
        const seconds = Math.floor((timeInMs % (60 * 1000)) / 1000);
        const milliseconds = Math.floor((timeInMs % 1000) / 10); // Show only two digits for ms
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}:${String(milliseconds).padStart(2, '0')}`;
    };

     // set saved paddle
    async  function getEquip() {
        const resp: any = await getEquipment();
        if (resp?.status && resp?.equipment?.paddle?.asset) {
           setPaddle(resp?.equipment?.paddle?.asset)
        }
    }

    function onPause() {

        setGameStarted(false);
    }

    // custom cursor
    function handleMouseMove(ev: any) {
        setMousePosition({left: ev.pageX -5 , top: ev.pageY + 10, });
    }
    function handleBGClick(ev: any) {
        apiPaddle.start({
            from: { transform: 'rotate3d(0, 0, 0, 0deg)' },
            to: [
                { transform: 'rotate3d(1, 0, 1, -3deg)' },
                { transform: 'rotate3d(1, 0, 1, 0deg)' },
            ],
            config: { tension: 200, friction: 15, duration: 200},
        });

        if (isMobile) {
            setTimeout(() => {
                setMousePosition({left: -250, top: -250 });
            }, 1000);
        }
    }


    useEffect(() => {
        getEquip();
        // for cursor
        const userAgent = navigator.userAgent || navigator.vendor;
        // Check for common mobile platforms
        if (/android/i.test(userAgent)) {
            setIsMobile(true);
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            setIsMobile(true);
        }
        document.addEventListener('touchmove', handleMouseMove);

        return () => {
            document.removeEventListener('touchmove', handleMouseMove);
        }
    }, []);

    useEffect(() => {
        const startInterval = () => {
            if (!intervalRef.current) {
                getNextPickleball();
                intervalRef.current = window.setInterval(() => {
                    getNextPickleball();
               }, 1500);
            }
        };

        const stopInterval = () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };


        // if screen is hidden to prevent animation
        const handleVisibilityChange = () => {
            if ((document.visibilityState === 'hidden') && isGameStarted) {
                cancelAllAnimations();
                stopInterval(); // Stop interval when tab is inactive
                setGameStarted(false);
            } else if ((document.visibilityState === 'visible') && isGameStarted && !timeOut) {
                // startInterval(); // Restart interval when tab becomes active
                // setGameStarted(true);
            }
        };

        if (!isGameStarted || timeOut) {
            cancelAllAnimations();
            stopInterval();
        }


        if (isGameStarted && !timeOut) {
            startInterval();
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            stopInterval();
        };
    }, [isGameStarted, timeOut]);


        const cancelAllAnimations = () => {
            ballType.forEach(ball => {
                if (cancelMap.has(ball)) {
                    cancelMap.get(ball)!(); // Cancel the animation for each ball
                }
            });
        };



  // get next ball from back-end API
    const getNextPickleball = async () => {
        const newBallInfo: any | DropI = await getNextBall();
        if (newBallInfo?.status) {
            const value = newBallInfo.drop.reward?.data?.value ? newBallInfo.drop.reward?.data?.value : 0;
             const ball = new PickleballClass(windowWidth, windowHeight, value, newBallInfo.drop?.id, isMobile);

            setNextBall(ball);
        }
    }
    useEffect(() => {
        if ( nextBall) {
            set((prevItems) => [...prevItems, nextBall]);
        }
    }, [nextBall]);

    // animations

    // splash screen
    const springApi = useSpringRef();
    const transitionsFirst = useTransition(0, {
        ref: springApi,
        key: 0,
        from: { opacity: 1, scale: 1},
        enter: { opacity: 0.5, scale: 1 },
        leave: { opacity: 0,  scale: 0 },
        config: { duration: 1500 },
        onRest: ( item) => {
            setOpen(true);
            dispatch(openApp());
        },
    });

    const transitionApi = useSpringRef();
    const isAppOpenedSaved = useSelector(state => (state as any).settings.isOpened);
    const isOpenedState = useMemo(() => !!isAppOpenedSaved, [isAppOpenedSaved]);

    // splash then ball animation
    useChain(open  ? [springApi] : [springApi, transitionApi], [0, 0.8]); // Delay between the two animations

    // ball animation
    const transitionsTest = useTransition<PickleballI, AnimatedStyle>(ballType, {
        from:  ball => ({ x: ball.left, y: '0', opacity: 1, height: ball.height }),
        keys: ball => ball.dropId,
        enter: (ball) => async (next, cancel) => {
            cancelMap.set(ball, cancel);  // Store the cancel function for each ball
            nextMap.set(ball, next);  // Store the next function for each ball
            await next({ x: ball.left, y: '85vh', height: ball.ballIncreasing });  // Move ball to random position
            await next({ x: ball.left, y: '140vh', height: 0, opacity: 0 });  // Move ball to random position
        },
        config: (item) => {
            // Set duration based on item value
            return {duration: item.duration, mass: 20, tension: 500, friction: 0}
        },
        leave:  ball => ({ opacity: 0, height: 0 }),
        onRest: (result, ctrl, ball) => {
            set(state => state.filter(b => b.dropId !== ball.dropId));  // Remove ball from the state
        },
    });

    // increase ball height on click
    const increaseHeightOnClick = async (ball: PickleballI) => {
        if (nextMap.has(ball)) {
            const next = nextMap.get(ball)!;

            // Animate height increase to 300px when clicked
            try {
                await next({height: 300});
            } catch (error) {
                if (error instanceof BailSignal) {
                    console.log('Animation was interrupted');
                }
            }
            // Now clear the ball
            setTimeout(() => {
                if (cancelMap.has(ball)) {
                    cancelMap.get(ball)!();  // Cancel and trigger removal
                }
            }, 200);

        }
    };

    // get click event from the Pickleball child component
    const updateParentClick = (event: {event: any, item: PickleballI}) => {
        increaseHeightOnClick(event.item);
        const x = event.event.clientX;
        const y = event.event.clientY;
        if (event.item.points) {
            setPoints(event.item.points);
            setIsClicked(true);
        }
        setCoordinates({x,y});

        setTimeout(() => {
            claimRequest(event.item);

        }, 200);
    };

    // get reward on click
    async function claimRequest(item: PickleballI) {
        if (localStorage.getItem('token')) {
            const resp: any = await getGameReward(item?.dropId);

            if (resp && resp.status && resp.reward?.data?.collection_id) {
                const rewardInfo = collectibles.find((item: any) => item.item_id === resp.reward.data.item_id);
                if (rewardInfo) {
                    setGameStarted(false);
                    setReward({image: rewardInfo.image, asset: rewardInfo.asset, rarity: rewardInfo.rarity, id: rewardInfo.item_id, name: rewardInfo.name, price: rewardInfo.price});
                }
            } else if (resp && resp.status && resp.reward?.data?.value) {
                const points = resp.reward.data.value;
                dispatch(incrementByAmount(points));
                setTimeout(() => {
                    setIsClicked(false);
                }, 1000);
            }
        }
    }
    function onCloseReward() {
        setReward({image: '', asset: '', rarity: '', id: '', name: '', price: 0});
        setGameStarted(true);
    }
    function onEquipReward() {
        onEquip();
        setPaddle(reward.asset);
        setReward({image: '', asset: '', rarity: '', id: '', name: '', price: 0});
        setGameStarted(true);
    }

    // save to back-end if we selected paddle for equip
    async function onEquip() {
        const resp = await equipItem(reward.id);
    }

    function onStartGame() {
        setTimeout(() => {
            // setTimeOutState(false);
            setGameStarted(true);
        }, 400);
    }


    return !open && !isOpenedState ? (
        <div style={{ display: 'flex', 'alignItems': 'center', 'height': '100%'}}>
            {transitionsFirst((style, i) => (
                <animated.div style={{...style, position: 'absolute', top: 0, left: 0,}}>
                    <Splash style={{...style}}
                    ></Splash>
                </animated.div>))}
        </div>) : (
        <TelegramScreen
            className={`main-wrapper--game animation-screenm ${timeOut ? 'main-wrapper--game--timeout' : ''}`}  id='gesuredZone'
            style={{cursor: 'none'}}
            onMouseMove={(ev: any)=> handleMouseMove(ev)}
            onTouchMove={(ev: any)=> handleMouseMove(ev)}
            onClick={(ev: any) => handleBGClick(ev)}
        >
            <img src={court} alt={'field'} className={`field-card ${timeOut ? 'field-card--timeout' : ''}`}/>

            <animated.div style={{...paddleProps, zIndex: 99999}}>
                <div  className={`cursor ${!isMobile ? 'cursor--desktop' : ''}`}
                  style={{left: MousePosition.left ,
                      top: MousePosition.top ,
                      backgroundImage: `url(${paddle})`
                  }}
            ></div>
            </animated.div>

            <div className='main-container'>
                <HeaderFunction></HeaderFunction>
                <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
                    {transitionsTest((style, ball) => (
                        <Pickleball
                            item={ball}
                            style={style}
                            onChange={updateParentClick}
                        ></Pickleball>
                    ))}
                </div>
                <div>
                    {clickTransitions((style, clicked) =>
                            clicked && (
                                <animated.div
                                    className='pickleScore'
                                    style={{
                                        ...style,
                                        top: coordinates?.y ? (coordinates.y + 10)  : '150px',
                                        left: coordinates?.x ? (coordinates.x - 30) : '150px',
                                        transform: 'rotate(-12deg)',
                                        zIndex: 1000
                                    }}
                                >
                                    {((points /3 ) === 2) && <div className='pickleScore--min'>double points</div>}
                                    {((points /3 ) === 3) && <div className='pickleScore--min-3'>triple points</div>}
                                    <div>+ {points} DINKS</div>
                                </animated.div>
                            )
                    )}
                </div>
                {reward?.image && (
                   <div className={'success-container'}>
                   <div style={{position: 'relative', marginTop: '40%'}}>
                       <img alt='reward' src={reward.image} style={{width: '250px', height: '325px' }}/>
                       <div className={'success-label'}>{reward.rarity}</div>
                   </div>

                    <div className={'success-card'}>
                        <p className={'success-card--title'}>You’ve received a drop!</p>
                        <p className={'success-card--text'}>Pickleball {reward.name} worth of {reward.price} DINKS</p>
                        <div className={'success-btns'}>
                            <button className={'success-btns--collect'} onClick={() => onEquipReward()}>Equip</button>
                            <button className={'success-btns--sell'} onClick={() => onCloseReward()}>Collect</button>
                        </div>
                    </div>
                </div>)}

                {(!isGameStarted || timeOut) && (
                  <div className={'game-start-button-container'}  onClick={() => onStartGame()}>
                   <div className={'game-start-button'}>
                       {!timeOut && (<button className={'game-start-button--icon'}></button>)}
                       {timeOut && (<p className={'game-start-button--sleep-time'}>
                           {formatTime(sleepTime)}</p>)}
                   </div>
                </div>)}

                <div className='forms__bottom'>
                    {(time && !timeOut && !reward?.image) ? (
                       <div className='cards__bottom--main'>
                       <div className='card__bottom--main'>
                            {/*{!timeStatus && (<ReactSVG  className='card__bottom--icon'*/}
                            {/*                            onClick={() => onTimerStateChange()}*/}
                            {/*                            src={playIcon}></ReactSVG>)}*/}
                            {isGameStarted && (
                                <img alt='reward' src={pauseIcon} style={{width: '26px', height: '26px' }}
                                     onClick={() => onPause()}/>

                                // <ReactSVG  className='card__bottom--icon'
                                //            style={{width: '30px', height: '30px'}}
                                //            onClick={() => onTimerStateChange()}
                                //            src={pauseIcon}></ReactSVG>
                        )}
                            <p>{formatTime(time)}</p>
                        </div>
                    </div>) : null}
                    <FooterFunction currentPage={'main'}/>

                </div>
            </div>
        </TelegramScreen>
    );
});

export default AnimationScreen;
